<template>
    <div v-if="modal" class="modal add_payment_method_modal" :class="{ active: modal }">
        <div class="modal-card">
            <p class="title" v-html="$t('payments.modal.add_payment_method.title')"></p>
            
            <form id="payment-form" class="payment-form">
                <div id="payment-element"></div>
                <span v-if="!loaded" class="cta loading"></span>
                <div id="error-message">
                    <p class="error" v-if="validation.error">{{validation.message}}</p>
                </div>
            </form>

            <div v-if="loaded" class="cta-container">
                <button class="cta cta-outline dark cta-slim" @click="cancel()">{{$t('payments.modal.add_payment_method.cancel')}}</button>
                <button :class="['cta cta-primary cta-slim', { loading }]" @click="submit()">{{$t('payments.modal.add_payment_method.add')}}</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AddPaymentMethodModal',

    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        show: function(val) {
            const self = this;
            
            self.modal = val;
            if(self.modal) {
                self.get_client_secret();
            }
        },

        modal: function() {
            const self = this;
            if(self.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },
    },

    data() {
        return {
            modal: false,
            stripe: null,
            elements: null,
            loading: false,
            loaded: false,

            validation: {
                error: false,
                message: ''
            }
        }
    },
    
    methods: {
        submit: async function() {
            const self = this;
            self.loading = true;

            await self.stripe.confirmSetup({
                elements: self.elements,
                confirmParams: {
                    return_url: `${process.env.VUE_APP_URL}/dashboard/payments`,
                }
            }).then(function(result) {
                if (result.error) {
                    self.validation.message = result.error.message;
                    self.validation.error = true;
                    self.loading = false;
                }
            });
        },

        get_client_secret: function() {
            const self = this;

            self.$axios
                .get('/payments/intent')
                .then(response => {
                    if(response.status == 200){
                        const client_secret = response.data.client_secret;
                        self.setup_form(client_secret);
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        setup_form: function(client_secret) {
            const self = this;

            const options = {
                clientSecret: client_secret
            };

            self.elements = self.stripe.elements(options);

            const payment_element = self.elements.create('payment');
            payment_element.mount('#payment-element');
            
            setTimeout(function () { 
                self.loaded = true;
            } , 500);
        },

        cancel: function() {
            const self = this;

            self.modal = false;
            self.$emit('cancel');
        },
    },

    mounted() {
        const self = this;

        const stripe_script = document.createElement('script')
        stripe_script.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(stripe_script);

        setTimeout(function () { 
            self.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK)
        } , 500);
    }
}
</script>

