<template>
    <div v-if="modal && after_add_payment_method" class="modal after_add_payment_method_modal" :class="{ active: modal }">
        <div class="modal-card">
            <div class="text-with-icon">
                <font-awesome-icon v-if="after_add_payment_method.status === 'succeeded'" class="success" :icon="['fa', 'circle-check']" />
                <font-awesome-icon v-if="after_add_payment_method.status === 'processing'" class="warning" :icon="['fa', 'triangle-exclamation']" />
                <font-awesome-icon v-if="after_add_payment_method.status === 'requires_payment_method'" class="error" :icon="['fa', 'circle-exclamation']" />
                
                <p class="title" v-html="$t(`payments.modal.after_add_payment_method.title.${after_add_payment_method.status}`)"></p>
            </div>

            <div class="modal-body">
                <p v-html="$t(`payments.modal.after_add_payment_method.description.${after_add_payment_method.status}`)"></p>
            </div>

            <div class="cta-container">
                <button class="cta cta-outline dark cta-slim" @click="close()">{{$t('payments.modal.after_add_payment_method.close')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'AfterAddPaymentMethodModal',

    props: {
        show: {
            type: Boolean,
            default: false
        },

        after_add_payment_method: {
            type: Object,
            default: null
        }
    },

    watch: {
        show: function(val) {
            const self = this;
            self.modal = val;
        },

        modal: function() {
            const self = this;
            if(self.modal) { 
                self.update_session();
                self.$utils._open_modal(); 
            }
            else { self.$utils._close_modal(); }
        }
    },

    data() {
        return {
            modal: false,
            icon: [],
        }
    },
    
    methods: {
        ...mapActions(['setToken']),
        
        close: function() {
            const self = this;

            self.modal = false;
            self.$emit('close');
        },

        update_session: function() {
            const self = this;

            self.$axios
                .get('account/session')
                .then(response => {
                    if(response.status == 200) {
                        self.setToken(response.data);
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                            console.log(JSON.stringify(error.response, null, 4))
                        }
                    } else { console.log(error); }
                    self.loaded = true;
                });
        }
    }
}
</script>

