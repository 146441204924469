<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded.method }">
        <div id="sticky-heading-container" class="heading-container sticky">
            <h1 class="title">{{$t(`payments.page.title`)}}</h1>
        </div>
        
        <div id="with-sticky-header" class="row with-sticky-header">
            <div class="left">
                <!-- <TransactionHistoryTable @loaded="loaded.history = true" /> -->
            </div>

            <div class="right">
                <PaymentMethodCard @loaded="loaded.method = true" @open="add_payment.modal = true" />
            </div>
        </div>
        
        <AddPaymentMethodModal :show="add_payment.modal" @cancel="add_payment.modal = false"/>
        <AfterAddPaymentMethod :show="after_add_payment_method.loaded" :after_add_payment_method="after_add_payment_method" @close="after_add_payment_method.loaded = false"/>
    </layout>
</template>

<script>
import Layout from '../../components/Layout.vue';
import PaymentMethodCard from '../../components/Cards/Payments/PaymentMethodCard.vue'
import AddPaymentMethodModal from '../../components/Modals/AddPaymentMethodModal.vue'
import AfterAddPaymentMethod from '../../components/Modals/AfterAddPaymentMethod.vue';
//import TransactionHistoryTable from '../../components/Tables/TransactionHistoryTable.vue';

export default {
    name: 'Payments',

    components: {
        Layout,
        PaymentMethodCard,
        AddPaymentMethodModal,
        AfterAddPaymentMethod,
        //TransactionHistoryTable
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'payments',
            classes: { dashboard: true, payments: true },
            
            add_payment: {
                modal: false
            },

            after_add_payment_method: {
                loaded: false,
                success: false,
                status: ''
            },

            loaded: {
                history: false,
                method: false
            }
        }
    },
    
    methods: {
        retrieve_query: async function(setup_intent) {
            const self = this;

            self.$axios
                .get(`payments/intent/${setup_intent}/validate`)
                .then(response => {
                    if(response.status == 200){
                        const status = response.data.status;
                        self.after_add_payment_method.loaded = true;

                        if(status === 'succeeded') { self.after_add_payment_method.success = true }
                        self.after_add_payment_method.status = 'succeeded';
                        self.$router.push({ name: 'payments' });
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        }
    },

    mounted() {
        const self = this;
        const query_params = self.$route.query;
        if(query_params && query_params.setup_intent) {
            self.retrieve_query(query_params.setup_intent);
        }
    }
}
</script>

